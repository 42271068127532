<template>
  <div>
    <div class="row q-pb-md">
      <div class="col-12">
        <c-card title="천연가스 (Natural gas, dried) 경고표지 상세" class="cardClassDetailInfo">
          <template slot="card-button">
            <q-btn-group outline >
              <c-btn 
                label="출력" 
                icon="save_alt" />
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <c-label-text title="물질명" value="천연가스 (Natural gas, dried)"></c-label-text>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <c-label-text title="납품업체" value="업체1"></c-label-text>
            </div>
            <div class="col-12">
              <c-label-text title="그림문자" view="image">
                <template slot="contents">
                  <div class="row">
                    <q-intersection
                      class="example-item"
                      style="display:inline;"
                    >
                      <img :src="require('@/assets/images/GHS02.gif')"
                        :style="{ width: '100px', }">
                    </q-intersection>
                    <q-intersection
                      class="example-item"
                      style="display:inline;"
                    >
                      <img :src="require('@/assets/images/GHS07.gif')"
                        :style="{ width: '100px', }">
                    </q-intersection>
                  </div>
                </template>
              </c-label-text>
            </div>
            <div class="col-12">
              <c-label-text title="유해위험 문구" :value="'극인화성 가스\n피부에 자극을 일으킴\n눈에 심한 자극을 일으킴'"></c-label-text>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: 'msds-hardcoding',
  data() {
    return {
      editable: true,
      data: {
        col1: '',
        col2: 'KE-25736',
        col3: '자료없음',
        col4: '자료없음',
        col5: '자료없음',
        col6: '-210 ℃',
        col7: '-222 ℃',
        col8: '연료 가스',
        col9: '극인화성 가스\n피부에 자극을 일으킴\n눈에 심한 자극을 일으킴',
        col10: '눈에 묻으면 몇 분간 물로 조심해서 씻으시오.\n가능하면 콘택트렌즈를 제거하시오. 계속 씻으시오.\n눈에 자극이 지속되면 의학적인 조치·조언을 구하시오.',
        col11: '긴급 의료조치를 받으시오.\n피부에 얼어붙은 옷은 제거하기전 해동하시오.\n화상의 경우 즉시 찬물로 가능한 오래 해당부위를 식히고, 피부에 들러붙은 옷은 제거하지 마시오.\n액화가스에 접촉한 경우 미지근한 물로 해당 부위를 녹이시오.\n가스 또는 액화 가스와 접촉 시 화상, 심각한 상해, 동상을 유발할 수 있음.\n피부에 묻으면 다량의 비누와 물로 씻으시오.\n피부 자극이 생기면 의학적인 조치·조언을 구하시오.\n오염된 의복은 벗고 다시 사용 전 세탁하시오.',
        col12: '신선한 공기가 있는 곳으로 옮기시오\n긴급 의료조치를 받으시오\n호흡하지 않는 경우 인공호흡을 실시하시오\n호흡이 힘들 경우 산소를 공급하시오\n따뜻하게 하고 안정되게 해주시오',
        col13: '긴급 의료조치를 받으시오',
        col14: '밀폐하여 보관하시오\n빈 드럼통은 완전히 배수하고 적절히 막아 즉시 드럼 조절기에 되돌려 놓거나 적절히 배치하시오.\n열·스파크·화염·고열로부터 멀리하시오 - 금연\n피해야할 물질 및 조건에 유의하시오\n환기가 잘 되는 곳에 보관하시오.',
        col15: '열·스파크·화염·고열로부터 멀리하시오 - 금연',
        col16: '자료없음',
        col17: '가능하다면 누출용기를 돌려 액체보다는 가스로 방출되도록 하시오\n가스가 완전히 확산되어 희석될 때까지 오염지역을 격리하시오\n냉동액체와의 접촉 물질은 쉽게 깨질 수 있음\n노출물을 만지거나 걸어다니지 마시오\n누출성 가스 화재 시 누출을 안전하게 막을 수 없다면 불을 끄려하지 마시오.\n누출원에 직접주수하지 마시오\n매우 미세한 입자는 화재나 폭발을 일으킬 수 있으므로 모든 점화원을 제거하시오.\n모든 점화원을 제거하시오\n물분무를 이용하여 증기를 줄이거나 증기구름을 흩뜨려서 물이 누출물과 접촉되지 않도록 하시오\n물질 취급시 모든 장비를 반드시 접지하시오\n엎질러진 것을 즉시 닦아내고, 보호구 항의 예방조치를 따르시오.\n위험하지 않다면 누출을 멈추시오\n피해야할 물질 및 조건에 유의하시오',
        col18: '자료없음',
        col19: '자료없음',
        col20: '자료없음',
        col21: '공정안전보고서(PSM) 제출 대상물질',
        col22: '자료없음',
        col23: '자료없음',
        col24: [1, 2, 3, 4],
      },
      comboItems: [
        { code: 1, codeName: '개인 보호구 착용', },
        { code: 2, codeName: '배기설비 가동 / 용기밀폐', },
        { code: 3, codeName: '금연 화기엄금', },
        { code: 4, codeName: '밀폐공간에서는 공기공급식 송기 마스크 착용, 면 마스크, 일반방진 방독 마스크 착용금지', },
      ],
      grid: {
        columns: [
          {
            name: 'col1',
            field: 'col1',
            label: '물질명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'col2',
            field: 'col2',
            label: 'CAS No.',
            align: 'center',
            sortable: true,
          },
          {
            name: 'col3',
            field: 'col3',
            label: '함유량(%)',
            align: 'center',
            sortable: true,
          },
        ],
        data: [
          { col1: '천연가스 (Natural gas, dried)', col2: '68410-63-9', col3: '100%', }
        ],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    warning() {
    },
  }
};
</script>
<style lang="sass">
.msdsCard
  .customCardbody
    padding-top: 5px !important
    padding-bottom: 5px !important
    margin-top: 0 !important
    .q-field__inner
      padding-bottom: 0 !important
</style>