var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row q-pb-md" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailInfo",
                attrs: { title: "천연가스 (Natural gas, dried) 경고표지 상세" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "출력", icon: "save_alt" },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "물질명",
                          value: "천연가스 (Natural gas, dried)",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                    [
                      _c("c-label-text", {
                        attrs: { title: "납품업체", value: "업체1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c(
                        "c-label-text",
                        { attrs: { title: "그림문자", view: "image" } },
                        [
                          _c("template", { slot: "contents" }, [
                            _c(
                              "div",
                              { staticClass: "row" },
                              [
                                _c(
                                  "q-intersection",
                                  {
                                    staticClass: "example-item",
                                    staticStyle: { display: "inline" },
                                  },
                                  [
                                    _c("img", {
                                      style: { width: "100px" },
                                      attrs: {
                                        src: require("@/assets/images/GHS02.gif"),
                                      },
                                    }),
                                  ]
                                ),
                                _c(
                                  "q-intersection",
                                  {
                                    staticClass: "example-item",
                                    staticStyle: { display: "inline" },
                                  },
                                  [
                                    _c("img", {
                                      style: { width: "100px" },
                                      attrs: {
                                        src: require("@/assets/images/GHS07.gif"),
                                      },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "유해위험 문구",
                          value:
                            "극인화성 가스\n피부에 자극을 일으킴\n눈에 심한 자극을 일으킴",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }